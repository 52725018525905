<template>
  <div>
    <v-row class="sticky-filters">
      <v-col cols="2">
        <base-credentials-selector
          :initial-value="{_id: taskFilters.credentials.value}"
          @select="selectCredentials"
        />
      </v-col>
      <v-col cols="2">
        <base-workflows-selector
          :initial-value="{_id: taskFilters.workflow.value}"
          @select=" $store.dispatch('tasks/SET_FILTERS', { workflow: { value: $event._id } });"
        />
      </v-col>
      <v-col cols="2">
        <base-selector-autocomplete
          :items="accounts"
          :initial-value="{}"
          item-text="full_name"
          item-value="_id"
          label="Select account"
          @select="(value) => selectFilter('account', value._id)"
        />
      </v-col>

      <v-col cols="2">
        <base-selector-autocomplete
          :items="apolloWorkersList"
          :initial-value="{}"
          item-text="_id"
          item-value="_id"
          label="Select worker"
          @select="(value) => selectFilter('worker', value._id)"
        />
      </v-col>
      <v-col cols="2">
        <base-selector-autocomplete
          :items="tasks"
          :initial-value="{}"
          item-text="name"
          item-value="_id"
          label="Select task"
          @select="(value) => selectFilter('task_type', value._id)"
        />
      </v-col>

      <v-col cols="2">
        <base-selector-autocomplete
          :items="statuses"
          :initial-value="{}"
          item-text="name"
          item-value="_id"
          label="Select status"
          @select="(value) => selectFilter('status', value._id)"
        />
      </v-col>
      <v-col cols="1">
        <v-btn @click="getTasks">
          Search
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn
          v-show="selectedTask._id"
          depressed
          color="secondary"
          class="mt-2"
          @click="retryTask"
        >
          Retry task
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        class="tasks"
      >
        <v-timeline clipped>
          <div
            v-for="(apTask, i) of apTasks"
            :key="apTask._id"
            @click="selectTask(i)"
          >
            <task-item
              :active="selectedTask._id === apTask._id"
              :task="apTask"
            />
          </div>
        </v-timeline>
      </v-col>
      <v-col cols="6">
        <prism-editor
          v-model="selectedTaskForEditor"
          :readonly="true"
          class="my-editor"
          :highlight="highlighter"
        />
      </v-col>
      <v-col
        v-if="error"
        cols="6"
      >
        <v-alert
          type="error"
        >
          {{ error }}
        </v-alert>
      </v-col>
      <v-progress-linear
        v-if="loading"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      />
    </v-row>
  </div>
</template>

<script>
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete';
  import { mapGetters } from 'vuex';
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css';
  import TaskItem from '@/components/tasksPage/TaskItem';
  import { lazyLoadMixin } from '@/mixins/lazyLoadMixin';

  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css';
  import { getApolloWorkers, getApolloTasks, retryApolloTask } from '@/api/apolloMethods';
  import BaseWorkflowsSelector from '@/components/base/selectors/BaseWorkflowsSelector.vue';
  import BaseCredentialsSelector from '@/components/base/selectors/BaseCredentialsSelector.vue';

  export default {
    name: 'ApolloCallsPage',
    components: {
      PrismEditor,
      TaskItem,
      BaseSelectorAutocomplete,
      BaseWorkflowsSelector,
      BaseCredentialsSelector,
    },
    mixins: [lazyLoadMixin],
    data () {
      return {
        selectedCallForEditor: 'Select apollo call',
        selectedCall: '',
        apolloWorkersList: [],
        apolloTask: [],
        apTasks: [],
        error: false,
        filters: {},
        selectedTask: {},
        selectedTaskForEditor: null,
        tasks: [
          { _id: '', name: 'unselect' },
          { _id: 'ap_fetch_people', name: 'ap_fetch_people' },
          { _id: 'ap_fetch_me', name: 'ap_fetch_me' },
          { _id: 'ap_logout', name: 'ap_logout' },
          { _id: 'ap_auth', name: 'ap_auth' },
          { _id: 'wait', name: 'wait' },
        ],
        statuses: [
          { _id: '', name: 'unselect' },
          { _id: 'unknown', name: 'unknown' },
          { _id: 'failed', name: 'failed' },
          { _id: 'done', name: 'done' },
          { _id: 'new', name: 'new' },
          { _id: 'wip', name: 'wip' },
        ],
      };
    },
    computed: {
      ...mapGetters({
        apolloCallsList: 'apollo/apolloCallsList',
        apolloClients: 'apollo/apolloClients',
        taskFilters: 'tasks/filters',
      }),
      accounts () {
        const accounts = [{ full_name: 'unselect', _id: '' }];
        [...this.apolloClients].forEach(e => {
          accounts.push({ full_name: e.email, _id: e._id });
        });
        return accounts;
      },
      task () {
        let q = '';
        this.apolloTask.forEach(e => {
          q += e;
        });
        return q;
      },
    },
    async created () {
      this.apolloWorkersList = await getApolloWorkers();
      this.apolloWorkersList.unshift({ _id: '' });
    },
    async mounted () {
      await this.$store.dispatch('apollo/SET_APOLLO_CLIENTS_LIST');
    },
    methods: {
      async selectCredentials (newCred) {
        this.$store.dispatch('tasks/SET_FILTERS', { workflow: '', credentials: { value: newCred?._id || '' } });
        await this.$store.dispatch('workflows/SET_NO_STATS_WORKFLOWS', newCred?._id || '');
        this.getTasks();
      },
      selectFilter (name, v) {
        if (v) {
          this.filters[name] = v;
        } else delete this.filters[name];
      },
      async getTasks () {
        const filters = {
          ...this.filters,
          credentials_id: this.taskFilters.credentials.value || '',
          workflow_id: this.taskFilters.workflow.value || '',
        };
        this.apTasks = await getApolloTasks(filters);
      },
      async retryTask () {
        if (this.selectedTask._id) {
          this.tasks.unshift(await retryApolloTask(this.selectedTask._id));
        }
      },
      highlighter (code) {
        return highlight(code, languages.js); // returns html
      },
      selectTask (index) {
        this.selectedTask = this.apTasks[index];
        this.selectedTaskForEditor = JSON.stringify(
          this.apTasks[index],
          null,
          '\t'
        ).replace(/"([^"]+)":/g, '$1:');
      },
    },
  };
</script>

<style scoped>
.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 25px;

  height: 70vh;
  width: 60vw;
  z-index: 1;
  white-space: pre-wrap;
}

.prism-editor__textarea:focus {
  outline: none;
}
.sticky-filters {
  position: sticky;
  top: 60px;
  margin-left: 8px;
  background: #fff;
  z-index: 3;
}
.tasks{
  overflow: scroll;
    max-height: 70vh;

}
</style>
